import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { OUTSIDE_API, URLS } from '../../../consts/endpoints';
import {
  doesArrayHasLength,
  isArray,
  isDefined,
  isObject,
  isUndefined
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { translate } from '../../../i18n/I18nProvider';

const SUBS_VALUES = {
  action: 'put_on_hold',
  allow_outporting: false,
  termination_date: null,
  after_termination_period: false
};

const getUser = ({ connectClientId, user, clientId }) => {
  const licenseTypes = {
    UC: translate('USER.LICENSE_UC'),
    LU: translate('USER.LIGHT_USER'),
    BL: translate('USER.BASIC_USER')
  };

  const { user_id, licenseType, simCards = [] } = user;

  return async dispatch => {
    const datacards = simCards.filter(card => card.data_card === true);

    const standardCards = simCards.filter(
      card => card.data_card !== true && card.card_type !== 'data_sharing_card'
    );
    let user = {
      licenseType: Optional(licenseTypes[licenseType]).or(licenseType),
      datacards,
      standardCards
    };

    let error = void 0;
    let values = {};

    try {
      const response = await getData(
        `${OUTSIDE_API.CLIENTS}/${connectClientId}/users/${user_id}`
      );

      if (isDefined(response)) {
        const mappedUser = mapUser(response);
        user = { ...user, ...mappedUser };
      }

      if (doesArrayHasLength(standardCards)) {
        const standardCardsWithSub = await Promise.all(
          standardCards.map(async el => {
            const { msisdn } = el;

            const { data } = await getData({
              options: {
                url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/search?phoneNumber[]=${msisdn}&execute_fetch_events=false&return_projections=false`
              },
              errorsToPass: [
                RESPONSE_CODES.CONNECTION_TIMEOUT,
                RESPONSE_CODES.INTERNAL_ERROR,
                RESPONSE_CODES.UNAUTHORIZED
              ]
            });

            const sub = Optional(data?.[msisdn]?.[0]).or(void 0);

            let subDetails = isObject(sub) ? { ...sub } : {};

            if (isDefined(sub)) {
              const detailsResponse = await getData(
                `${URLS.QUOTE_CLIENTS_V2}/${clientId}/subscriptions/${sub.externalReference}`
              );

              if (isDefined(detailsResponse)) {
                subDetails = detailsResponse;
              }

              values = {
                ...values,
                [sub.id]: { ...SUBS_VALUES }
              };
            }

            if (isUndefined(sub)) {
              error = 'There is a problem with fetching subscription data';
            }

            return {
              ...el,
              sub: isDefined(sub) ? { ...sub, subDetails } : void 0
            };
          })
        );

        user = { ...user, standardCardsWithSub };
      }
      if (doesArrayHasLength(datacards)) {
        const datacardsCardsWithSub = await Promise.all(
          datacards.map(async el => {
            const { msisdn } = el;
            const { data } = await getData({
              options: {
                url: `${URLS.QUOTE_CLIENTS}/${clientId}/subscriptions/search?phoneNumber[]=${msisdn}&execute_fetch_events=false&return_projections=false`
              },
              errorsToPass: [
                RESPONSE_CODES.CONNECTION_TIMEOUT,
                RESPONSE_CODES.INTERNAL_ERROR,
                RESPONSE_CODES.UNAUTHORIZED
              ]
            });
            const sub = Optional(data?.[msisdn]?.[0]).or(void 0);

            if (isDefined(sub)) {
              values = {
                ...values,
                [sub.id]: { ...SUBS_VALUES, action: 'terminate' }
              };
            }

            if (isUndefined(sub)) {
              error = 'There is a problem with fetching subscription data';
            }

            return { ...el, sub };
          })
        );

        user = { ...user, datacardsCardsWithSub };
      }
    } catch (e) {
      error = 'There is a problem with fetching subscription data';
    } finally {
      dispatch(
        reduxActions.openTerminateUser({
          user,
          error,
          values
        })
      );
    }
  };
};

export default getUser;

const mapUser = data => {
  if (!isObject(data)) {
    return {};
  }

  const { name, user_id, email, phone_numbers, user_profile } = data;

  const numbers = mapNumbers(phone_numbers);

  return { name, user_id, email, user_profile, ...numbers };
};

const mapNumbers = numbers => {
  if (!isArray(numbers)) {
    return {};
  }
  const fixed = numbers
    .filter(el => el.number_type === 'OFFICE_FIXED')
    .map(el => el.value);
  const mobile = numbers
    .filter(el => el.number_type === 'OFFICE_MOBILE')
    .map(el => el.value);

  return { fixed, mobile };
};
