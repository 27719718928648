import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React, { useCallback, useMemo } from 'react';
import locale from 'date-fns/locale/en-US';
import { ThemeProvider } from '@material-ui/styles';

import Optional from '../../../utils/optional';
import { checkNonWorkingDays } from '../../../utils/dateUtils';
import withSettings from '../../../utils/withSettings';
import { materialDatePickerTheme } from '../../editSchedule/styles/datePickers';

const DANISH_COUNTRY_CODE = 'DK';

const DatePicker = ({ values, setValues, settings }) => {
  const handleDateChange = useCallback(
    selected => {
      setValues(selected);
    },
    // eslint-disable-next-line
    [values]
  );

  const materialDateTheme = useMemo(() => {
    return materialDatePickerTheme(settings);
  }, [settings]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <ThemeProvider theme={materialDateTheme}>
        <KeyboardDatePicker
          inputVariant="outlined"
          margin="dense"
          value={Optional(values?.termination_date).or(null)}
          onChange={handleDateChange}
          disabled={Optional(values?.after_termination_period).or(false)}
          disablePast={true}
          autoOk={true}
          format="dd/MM/yyyy"
          placeholder="dd/MM/yyyy"
          showTodayButton
          shouldDisableDate={date =>
            checkNonWorkingDays({ date, countryCode: DANISH_COUNTRY_CODE })
          }
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default withSettings(DatePicker);
